import { useDeferredValue, useState } from 'react';
import { Select } from '@components/form';
import { FullscreenModal, FullscreenModalContainer } from '@components/modal';
import type {
  TRenderTrigger,
  TSelectedCountry,
  TOnCountryChange,
} from '../../../types';
import { CountryItem } from './country-item';
import { useGetCountries } from '@entities/common/model';

export interface ICountrySelectProps {
  country?: TSelectedCountry;
  onCountryChange?: TOnCountryChange;
  title?: string;
  isCentered?: boolean;
  modalTitle?: string;
  renderTrigger: TRenderTrigger<TSelectedCountry>;
  excludedCountries?: string[];
}

export const CountrySelect = ({
  country,
  onCountryChange,
  title,
  isCentered,
  modalTitle,
  renderTrigger,
  excludedCountries,
}: ICountrySelectProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const [search, setSearch] = useState('');
  const deferredSearch = useDeferredValue(search);

  const { countries, isLoading } = useGetCountries({ query: deferredSearch });

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const onChange: Select.ConfirmableSelect.IProviderProps['onChange'] = (
    country,
  ) => {
    const newCountry = countries.find((c) => c === country);
    onCountryChange && onCountryChange(newCountry || undefined);
  };

  return (
    <Select.ConfirmableSelect.Provider
      value={country}
      onChange={onChange}
      onListOpen={open}
      onListClose={close}
      searchValue={search}
      setSearch={setSearch}
      isOpen={isOpen}
    >
      {renderTrigger({ onListOpen: open, active: country })}

      <FullscreenModal open={isOpen} onOpenChange={setIsOpen}>
        <FullscreenModalContainer title={modalTitle} isCentered={isCentered}>
          <Select.ConfirmableSelect.List title={title} isLoading={isLoading}>
            {countries.map((country) =>
              !excludedCountries?.includes(country) ? (
                <CountryItem
                  key={country}
                  country={country}
                  isLoading={isLoading}
                />
              ) : null,
            )}
          </Select.ConfirmableSelect.List>
        </FullscreenModalContainer>
      </FullscreenModal>
    </Select.ConfirmableSelect.Provider>
  );
};
