import { useState, useMemo, useEffect, useRef } from 'react';
import { Select } from '@components/form';
import { FullscreenModal, FullscreenModalContainer } from '@components/modal';
import type {
  ConfirmableSelect,
  TOnAccountChange,
  TSelectedAccount,
} from '@components/form/select';
import { AccountItem } from './account-item';
import {
  useGetAccounts,
  IGetAccountsParams,
  IAccount,
} from '@entities/accounts/model';
import { CurrencyDropdownButton } from '../../currency-dropdown-button';
import { getAccountItemName } from '@entities/accounts/lib';

export interface IAccountSelectProps {
  account?: TSelectedAccount;
  onAccountChange?: TOnAccountChange;
  title?: string;
  isCentered?: boolean;
  modalTitle?: string;
  hiddenAccounts?: string[];
  requestParams?: IGetAccountsParams;
  filterFn?: (acc: IAccount) => boolean;
  autoSelectFirst?: boolean;
  autoSelectId?: string;
}

export const AccountSelect = ({
  account,
  title,
  onAccountChange,
  isCentered,
  modalTitle,
  hiddenAccounts = [],
  requestParams,
  filterFn,
  autoSelectFirst,
  autoSelectId,
}: IAccountSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const didAutoSelect = useRef(false);

  const { data } = useGetAccounts(requestParams);

  const baseFilteredAccounts = useMemo(() => {
    const accounts = data?.results || [];
    return accounts.filter((acc) => !hiddenAccounts.includes(acc.id));
  }, [data, hiddenAccounts]);

  const finalAccounts = useMemo(() => {
    if (filterFn) {
      return baseFilteredAccounts.filter(filterFn);
    }

    return baseFilteredAccounts;
  }, [baseFilteredAccounts, filterFn]);

  useEffect(() => {
    if (didAutoSelect.current || finalAccounts.length === 0 || account) {
      return;
    }

    if (autoSelectId) {
      const found = finalAccounts.find((acc) => acc.id === autoSelectId);

      if (found) {
        didAutoSelect.current = true;
        onAccountChange?.(found);
        return;
      }
    }

    if (autoSelectFirst) {
      didAutoSelect.current = true;
      onAccountChange?.(finalAccounts[0]);
    }
  }, [autoSelectFirst, autoSelectId, account, finalAccounts, onAccountChange]);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const onChange: ConfirmableSelect.IProviderProps['onChange'] = (id) => {
    const newAccount = finalAccounts.find((acc) => acc?.id === id);
    onAccountChange?.(newAccount);
  };

  return (
    <Select.ConfirmableSelect.Provider
      value={account?.id}
      onChange={onChange}
      onListOpen={open}
      onListClose={close}
      isOpen={isOpen}
    >
      <CurrencyDropdownButton
        currency={account?.currency}
        data-test="dropdown-btn"
        name={getAccountItemName(account)}
      />

      <FullscreenModal open={isOpen} onOpenChange={setIsOpen}>
        <FullscreenModalContainer title={modalTitle} isCentered={isCentered}>
          <Select.ConfirmableSelect.List title={title} isSearchable={false}>
            {finalAccounts.map((acc) => (
              <AccountItem
                account={acc}
                key={acc.id}
                data-test={'currency-item'}
              />
            ))}
          </Select.ConfirmableSelect.List>
        </FullscreenModalContainer>
      </FullscreenModal>
    </Select.ConfirmableSelect.Provider>
  );
};
