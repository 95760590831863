import React, { useEffect, useState } from 'react';

import { formatBalance } from '@components/transactions/util';
import { ClientModel } from '@entities/client';
import { useGetClientBalance } from '@entities/client/model';
import { useGetCurrencies } from '@entities/finance/model';
import { useBreakpoint } from '@shared/hooks/use-breakpoint';
import { displaySuccessForOfflineAction } from '@shared/lib';
import { useQueryClient } from '@tanstack/react-query';
import { Skeleton } from '@ui/skeleton';

import { SelectCurrency } from './select-currency';

import type {
  TOnCurrencyChange,
  TSelectedCurrency,
} from '@components/form/select';
export const getCurrencySymbol = (currency: string): string => {
  const symbols: { [key: string]: string } = {
    USD: '$',
    EUR: '€',
    GBP: '£',
    // Actually we don't need RUB at all (with prod data)
    RUB: 'RUB',
    // Add more currency symbols as needed
  };
  return symbols[currency] || currency;
};

export const TotalBalance: React.FC = () => {
  const queryClient = useQueryClient();
  const { data: profile, isLoading: isProfileLoading } =
    ClientModel.useGetClientProfile();
  const [currency, setCurrency] = useState<TSelectedCurrency>(
    profile?.defaultCurrency,
  );
  const { balance, isLoading: isBalanceLoading } = useGetClientBalance(
    currency!,
    { enabled: !!currency },
  );
  const { data: currencies, isLoading: isCurrenciesLoading } =
    useGetCurrencies();
  const { mutate: updateProfile } = ClientModel.useUpdateClientProfile();
  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (profile?.defaultCurrency) {
      setCurrency(profile.defaultCurrency);
    }
  }, [profile]);

  const title = breakpoint.up('md')
    ? 'Total balance from all accounts'
    : 'Total balance';

  const totalBalance = balance ? balance.checking + balance.saving : 0;
  const formattedBalance = formatBalance(totalBalance);
  const currencySymbol = currency && getCurrencySymbol(currency);

  const handleCurrencyChange: TOnCurrencyChange = async (newCurrency) => {
    setCurrency(newCurrency);

    updateProfile(
      { defaultCurrency: newCurrency! },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ClientModel.queryKeyGetClientProfile,
          });
        },
        onError: () => !navigator.onLine && displaySuccessForOfflineAction(),
      },
    );
  };

  const isLoading =
    isBalanceLoading ||
    isCurrenciesLoading ||
    isProfileLoading ||
    !currencySymbol;

  return (
    <div data-test="total-balance">
      <div className="flex items-center justify-between">
        <h5 className="text-[0.6875rem] uppercase tracking-widest text-neutral01 opacity-50">
          {title}
        </h5>
        <SelectCurrency
          currency={currency as TSelectedCurrency}
          setCurrency={handleCurrencyChange}
          availableCurrencies={currencies?.currencies || []}
        />
      </div>
      {isLoading ? (
        <Skeleton className="h-[36px] w-[134px]" />
      ) : (
        <p className="mt-3 text-[2rem] leading-[1.375rem]">
          {`${formattedBalance} ${currencySymbol}`}
        </p>
      )}
    </div>
  );
};
