import AddDashedCircleIcon from '@assets/icons/add-dashed-circle.svg';
import ChainlinkIcon from '@assets/icons/chainlink.svg';
import { CurrencyIcon } from '@components/currency-icon';
import { Select } from '@components/form';

import { TSelectedCurrency } from '../types';

export interface ICurrencyDropdownButtonProps {
  currency?: TSelectedCurrency;
  name?: string | null | undefined;
}

export const CurrencyDropdownButton = ({
  currency,
  name,
  ...props
}: ICurrencyDropdownButtonProps) => {
  const renderIcon = () =>
    currency ? (
      <CurrencyIcon
        className="size-5"
        currency={currency}
        emptyIcon={
          <img src={ChainlinkIcon} className="size-5" alt="empty icon" />
        }
      />
    ) : (
      <img src={AddDashedCircleIcon} className="size-5" alt="circle icon" />
    );

  return (
    <Select.ConfirmableSelect.DropdownButton {...props}>
      {renderIcon()}
      <span className="mx-3">{name || 'ADD'}</span>
    </Select.ConfirmableSelect.DropdownButton>
  );
};
