import { CurrencyIcon } from '@components/currency-icon';
import { Select } from '@components/form';
import { getAccountItemName } from '@entities/accounts/lib';
import { EmptyIcon } from '../../common';

export interface ICurrencyItemProps {
  account?: Select.TSelectedAccount;
}

export const AccountItem = ({ account, ...props }: ICurrencyItemProps) => (
  <Select.ConfirmableSelect.Item
    key={account?.id}
    value={account?.id}
    {...props}
  >
    <CurrencyIcon
      className="size-5"
      currency={account?.currency}
      emptyIcon={<EmptyIcon />}
    />
    <span>{getAccountItemName(account)}</span>
  </Select.ConfirmableSelect.Item>
);
