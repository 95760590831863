/* eslint-disable max-len */
import { safeEnv } from '@/shared/lib';
import getCookieHandler from '@app/config/cookieHandler.ts';
import { useImpersonationStore } from '@shared/api/impersonation';
import SuperTokens from 'supertokens-auth-react';
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';
import EmailVerification from 'supertokens-auth-react/recipe/emailverification';
import { EmailVerificationPreBuiltUI } from 'supertokens-auth-react/recipe/emailverification/prebuiltui';
import MultiFactorAuth from 'supertokens-auth-react/recipe/multifactorauth';
import { MultiFactorAuthPreBuiltUI } from 'supertokens-auth-react/recipe/multifactorauth/prebuiltui';
import Passwordless from 'supertokens-auth-react/recipe/passwordless';
import { PasswordlessPreBuiltUI } from 'supertokens-auth-react/recipe/passwordless/prebuiltui';
import Session from 'supertokens-auth-react/recipe/session';

const strategyStyles = `
        [data-supertokens~=container] {
          background: none !important;
          max-width: 480px !important;
          width: 100% !important;
          font-family: 'TTFirsNeue', sans-serif !important;
          padding-inline: 24px !important;
          box-sizing: border-box !important;
          margin-inline: 24px !important;
        }

        [data-supertokens="primaryText enterEmailSuccessMessage"], [data-supertokens="primaryText submitNewPasswordSuccessMessage"] {
          color: var(--Neutral-01, #F6F6F9) !important;
        }

       [data-supertokens="container resetPasswordPasswordForm"] {
          background: none !important
       }

        [data-supertokens~=authPage], [data-supertokens~=resetPasswordEmailForm], [data-supertokens~=pwless-mfa] {
          background: none !important;
          margin: 0 !important;
        }

        [data-supertokens~=authPage] [data-supertokens~=spinnerIcon] *, [data-supertokens~=pwless-mfa] [data-supertokens~=spinnerIcon] *, [data-supertokens~=resetPasswordEmailForm] [data-supertokens~=spinnerIcon] * {
          stroke: var(--Neutral-01, #F6F6F9) !important;
        }

        [data-supertokens~=authPage] [data-supertokens~="primaryText"], [data-supertokens~=resetPasswordEmailForm] [data-supertokens~="primaryText"], [data-supertokens~=pwless-mfa] [data-supertokens~="primaryText"] {
          color: unset !important;
        }

        [data-supertokens~=container] form {
          display: flex !important;
          flex-direction: column !important;
          gap: 24px !important;
        }

        [data-supertokens~="withBackButton"] {
          gap: 10px !important;
        }

        [data-supertokens~=headerTitle], [data-supertokens~=headerSubtitle] {
          color: var(--Neutral-01, #F6F6F9) !important;
        }

        [data-supertokens~=authPage] [data-supertokens~=headerSubtitle] {
            display: none;
        }

        [data-supertokens~=authPage] [data-supertokens~=headerTitle] {
            display: none;
        }

        [data-supertokens~='divider'] {
          display: none !important;
        }

        [data-supertokens~='label'] {
          color: var(--Neutral-01, #F6F6F9) !important;
          font-variant-numeric: lining-nums tabular-nums !important;
          font-feature-settings: 'cv02' on, 'cv03' on, 'cv04' on, 'cv11' on, 'liga' off, 'clig' off, 'calt' off !important;
          font-size: 16px !important;
          font-style: normal !important;
          font-weight: 500 !important;
          line-height: 150% !important;
          margin-bottom: 6px !important;
        }

        [data-supertokens~="formRow"] {
          padding-bottom: 0 !important;
        }

        [data-supertokens~=container] > [data-supertokens~='row'] {
          width: 100% !important;
        }

        [data-supertokens~='superTokensBranding'] {
          display: none !important;
        }

        [data-supertokens~='inputWrapper'] {
          border: none !important;
          outline: none !important;
          height: 52px !important;
          overflow: hidden !important;
          background: var(--Neutral-07, #1A1D1F) !important;
        }

        [data-supertokens="inputContainer"] {
          margin: 0 !important;
          overflow: hidden !important;
          border-radius: 8px !important;
        }

        [data-supertokens~='inputWrapper']:focus-within {
          outline: none !important;
          box-shadow: none !important;
        }

        [data-supertokens~='inputWrapper'] > input {
          height: 100% !important;
          padding: 14px 16px !important;
          border: none !important;
          outline: none !important;
          color: var(--Neutral-01, #F6F6F9) !important;
          border-radius: 0 !important;
          font-family: 'TTFirsNeue', sans-serif !important;
        }

        [data-supertokens~='inputWrapper'] > input:-webkit-autofill {
          background-color: light-dark(rgb(255, 235, 153), rgba(70, 90, 126, 0.4)) !important;
          box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000) !important;
          -webkit-text-fill-color: var(--Neutral-01, #F6F6F9) !important;
        }

        [data-supertokens~='button'] {
          display: flex !important;
          height: 60px !important;
          padding: 12px 24px !important;
          justify-content: center !important;
          align-items: center !important;
          font-family: 'TTFirsNeue', sans-serif !important;
          gap: 8px !important;
          align-self: stretch !important;
          border-radius: 12px !important;
          background: var(--Neutral-01, #F6F6F9) !important;
          color: black !important;
          border: none !important;
          leading-trim: both !important;
          text-edge: cap !important;
          font-feature-settings: 'liga' off, 'calt' off !important;
          font-size: 16px !important;
          font-style: normal !important;
          font-weight: 500 !important;
          line-height: 100% !important;
          letter-spacing: 0.16px !important;
          text-transform: capitalize !important;
        }

        [data-supertokens~="backButton"] {
          background: var(--Neutral-01, #F6F6F9) !important;
          border-radius: 12px !important;
          min-width: 30px !important;
          min-height: 30px !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
        }

        [data-supertokens~="backButton"] svg {
          min-width: 12px !important;
        }

        [data-supertokens~="backButtonCommon"] {
          min-width: 30px !important;
        }

        [data-supertokens~=container] a {
          color: #F6F6F9 !important;
          opacity: 0.5 !important;
          text-decoration: underline !important;
          transition: opacity 0.2s ease-in-out !important;
          font-family: 'TTFirsNeue', sans-serif !important;
        }

        [data-supertokens~=container] a:hover {
          opacity: 0.4 !important;
        }

        [data-supertokens~="inputAdornment"] {
          margin-right: 16px !important;
        }

        [data-supertokens~="inputAdornmentSuccess"] path {
          fill: var(--Neutral-01, #F6F6F9) !important;
        }

        [data-supertokens~=spinnerIcon] * {
          stroke: var(--Neutral-01, #F6F6F9) !important;
        }
`;

const defaultStyles = `
  [data-supertokens~=spinnerIcon] * {
    stroke: black !important;
  }
`;

const isImpersonationToken =
  !!useImpersonationStore.getState().impersonationToken;

SuperTokens.init({
  cookieHandler: isImpersonationToken ? undefined : getCookieHandler,
  appInfo: {
    appName: 'GCard: A premium payment card',
    apiDomain: safeEnv.VITE_CLIENT_API,
    websiteDomain: safeEnv.VITE_CLIENT_URL,
    apiBasePath: '/auth',
    websiteBasePath: '/auth',
  },
  style: defaultStyles,
  recipeList: [
    EmailPassword.init({
      style: strategyStyles,
      preAPIHook: async (context) => {
        let requestInit = context.requestInit;

        if (context.action === 'EMAIL_PASSWORD_SIGN_IN') {
          const headers = {
            ...requestInit.headers,
            'x-gcard-origin': 'client',
          };
          requestInit = {
            ...requestInit,
            headers,
          };
        }
        return {
          url: context.url,
          requestInit,
        };
      },
    }),
    EmailVerification.init({
      mode: 'REQUIRED',
    }),
    Session.init({
      tokenTransferMethod: isImpersonationToken ? undefined : 'header',
    }),
    Passwordless.init({
      contactMethod: 'EMAIL',
      style: strategyStyles,
    }),
    MultiFactorAuth.init({
      firstFactors: [MultiFactorAuth.FactorIds.EMAILPASSWORD],
    }),
  ],
});

export const PreBuiltUIList = [
  EmailPasswordPreBuiltUI,
  EmailVerificationPreBuiltUI,
  PasswordlessPreBuiltUI,
  MultiFactorAuthPreBuiltUI,
];
